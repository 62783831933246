// @js-import "@shared/vendor/jquery-1.9.1.min.js"
// @js-import "@shared/jquery-fn.js"
// @js-import "@shared/components.js"
// @js-import "@shared/components-utils.js"
// @js-import "@shared/screen-size.js"
// @js-import "@shared/CmsMenuUtils.js"

ComponentsManager.register('footer', function() {
    var ScreenSize = rwd.ScreenSize;

    var privateMethods = {
        selectors: {
            toTop: '.js-footer__scrollTop',
        },
        scrollToTop: function($componentElement) {
            $componentElement.onFooterEvent("click", privateMethods.selectors.toTop, function() {
                $("html, body").animate({ scrollTop: 0 }, "slow");
             });
        },
        setCurrentYearIntoCopyright: function($componentElement) {
            var copyright = $componentElement.find('.js-footer__copyrightYear'),
                date      = new Date(),
                dateYear  = date.getFullYear();
            return copyright.text(dateYear);
        }
    };

    return {
        init: function(componentId, $componentElement) {
            privateMethods.scrollToTop($componentElement);
            privateMethods.setCurrentYearIntoCopyright($componentElement);

        }
    }
 });
